<template>
	<div>
    <div class="content-info-box01">
      특별 운영 시간을 추가할 경우, 기본 운영 시간보다 우선 되어 특별 운영 시간으로 상담 서비스가 운영 됩니다.
    </div>

    <div class="head-btn-box01">
      <div class="head-btn-left">
        <DxButton
          text="추가"
          type="button"
          class="btn_XS default filled add1"
          :height="34"
          @click="onAddData"
        />
        <DxButton
          text="삭제"
          type="button"
          class="btn_XS white light_filled trash"
          :height="34"
          :disabled="specialTime.listData.filter(d => d.checked).length === 0"
          @click="onDeleteData('specialTime')"
        />
      </div>
      <div class="head-btn-right">
        <!--"single_text_line"검색결과 1개만 있을때 적용-->
        <div class="top-right-txt-box single_text_line">
          검색결과 <span class="tet-calr1">{{ specialTime.listData.length }}</span> 개
        </div>
      </div>
    </div>

    <div class="cusmain-table-wrap">
      <h2 class="title more" style="font-weight: normal"></h2>

      <table class="table_list">
        <caption>
          <strong>특별 운영 시간 설정</strong>
        </caption>
        <colgroup>
          <col style="width: 78px" />
          <col style="width: 15%" />
          <col style="width: auto" />
          <col style="width: auto" />
          <col style="width: auto; min-width: 350px" />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">
              <DxCheckBox
                v-model="specialTime.allChecked"
                @value-changed="onChangeAllCheck(specialTime)"
              />
            </th>
            <th scope="col">일자</th>
            <th scope="col">운영 시간</th>
            <th scope="col" class="word-all-view">휴식시간 적용여부</th>
            <th scope="col">휴식시간</th>
          </tr>
        </thead>
        <tbody v-if="specialTime.listData.length > 0">
          <tr v-for="(item, index) in specialTime.listData" :key="index">
            <td>
              <DxCheckBox
                v-model="item.checked"
                @value-changed="onChangeChecked('specialTime')"
              />
            </td>
            <td class="taC">
              <!-- YYYY.MM.DD -->
              <div class="ui-datepicker-item">
                <DxDateBox
                  :width="config.dateBox.width"
                  :type="config.dateBox.type"
                  :display-format="config.dateBox.displayFormat"
                  :styling-mode="config.stylingMode"
                  v-model="item.specialDt"
                >
                  <DxValidator>
                    <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '일자' })" />
                  </DxValidator>
                </DxDateBox>
              </div>
            </td>
            <td class="taC">
              <!-- hh:mm ~ hh:mm -->
              <div class="ui-datepicker period">
                <div class="ui-datepicker-item">
                  <DxDateBox
                    :styling-mode="config.stylingMode"
                    :edit-enabled="config.timeDateBox.editEnabled"
                    :width="config.timeDateBox.width"
                    :type="config.timeDateBox.type"
                    :max="item.workEnd"
                    :picker-type="config.timeDateBox.pickerType"
                    :interval="config.timeDateBox.interval"
                    :display-format="config.timeDateBox.displayFormat"
                    :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                    :disabled="item.checkedDays"
                    v-model="item.workStart"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '시작시간' })" />
                    </DxValidator>
                  </DxDateBox>
                  <!-- :max="item.workEnd" 버그 있음-->
                </div>
                <span class="dash">~</span>
                <div class="ui-datepicker-item">
                  <DxDateBox
                    :edit-enabled="config.timeDateBox.editEnabled"
                    :styling-mode="config.stylingMode"
                    :width="config.timeDateBox.width"
                    :type="config.timeDateBox.type"
                    :min="item.workStart"
                    :picker-type="config.timeDateBox.pickerType"
                    :interval="config.timeDateBox.interval"
                    :display-format="config.timeDateBox.displayFormat"
                    :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                    :disabled="item.checkedDays"
                    v-model="item.workEnd"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '종료시간' })" />
                    </DxValidator>
                  </DxDateBox>
                  <!-- :min="item.workStart" -->
                </div>
                <span class="check-type col mar_le15 word-all-view">
                  <DxCheckBox
                    text="24시"
                    v-model="item.checkedDays"
                    @value-changed="onChangedCheckedDays(item)"
                  >
                  </DxCheckBox>
                </span>
              </div>
            </td>
            <td class="taC locker_switch_box clearfix">
              <div class="locker_switch t_in_swutch">
                <DxSwitch v-model="item.isBreakTime" @value-changed="onChangeIsBreakTime(item)" />
              </div>
            </td>
            <td>
              <!-- hh:mm ~ hh:mm -->
              <div
                class="clearfix view-picker-box"
                v-for="(item2, index2) in item.breakTimeList"
                :key="index2"
              >
                <div class="ui-datepicker period fl">
                  <div class="ui-datepicker-item">
                    <DxDateBox
                      :edit-enabled="config.timeDateBox.editEnabled"
                      :styling-mode="config.stylingMode"
                      :width="config.timeDateBox.width"
                      :type="config.timeDateBox.type"
                      :max="item2.breakEnd"
                      :picker-type="config.timeDateBox.pickerType"
                      :interval="config.timeDateBox.interval"
                      :display-format="config.timeDateBox.displayFormat"
                      :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                      :disabled="!item.isBreakTime"
                      v-model="item2.breakStart"
                    >
                      <DxValidator>
                        <DxRangeRule
                          :max="item2.breakEnd"
                          :message="$_msgContents('CC.MESSAGE.START_TIME_OUT_OF_RANGE', { defaultValue: '시작시간은 종료시간보다 작거나 같아야 합니다.' })"
                        />
                      </DxValidator>
                    </DxDateBox>
                  </div>
                  <span class="dash">~</span>
                  <div class="ui-datepicker-item">
                    <DxDateBox
                      :edit-enabled="config.timeDateBox.editEnabled"
                      :styling-mode="config.stylingMode"
                      :width="config.timeDateBox.width"
                      :type="config.timeDateBox.type"
                      :min="item2.breakStart"
                      :picker-type="config.timeDateBox.pickerType"
                      :interval="config.timeDateBox.interval"
                      :display-format="config.timeDateBox.displayFormat"
                      :date-serialization-format="config.timeDateBox.dateSerializationFormat"
                      :disabled="!item.isBreakTime"
                      v-model="item2.breakEnd"
                    >
                      <DxValidator>
                        <DxRangeRule
                          :min="item2.breakStart"
                          :message="$_msgContents('CC.MESSAGE.END_TIME_OUT_OF_RANGE', { defaultValue: '종료시간은 시작시간보다 크거나 같아야 합니다.' })"
                        />
                      </DxValidator>
                    </DxDateBox>
                  </div>
                </div>
                <template v-if="index2 === 0 && item.isBreakTime">
                  <i class="time_add adminicon_02" @click="onAddBreakTime(item)"></i>
                </template>
                <template v-if="index2 !== 0 && item.isBreakTime">
                  <i
                    class="minus-ico adminicon_02"
                    @click="onRemoveBreakTime('specialTime', index, index2)"
                  ></i>
                </template>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">추가된 데이터가 없습니다.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { DxRangeRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';

import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxButton } from 'devextreme-vue/button';
import { getPastFromToday, cloneObj } from '@/plugins/common-lib';

export default {
	components: {
		DxValidator,
		DxRequiredRule,
		DxRangeRule,

		DxCheckBox,
		DxSwitch,
		DxDateBox,
		DxButton,
	},
	props: {
    /** @description : 특별운영시간 데이터 */
    specialTimeList: {
      type: Array,
    },
  },
	watch: {
    /** @description : 특별운영시간 데이터 */
    specialTimeList(value) {
      this.specialTime.listData = value;
    },
  },
	data() {
		return {
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        dateBox: {
          width: 120,
          type: 'date',
          displayFormat: 'yyyy.MM.dd',
        },
        timeDateBox: {
          editEnabled: false,
          width: 120,
          type: 'time',
          displayFormat: 'a HH:mm',
          dateSerializationFormat: 'HH:mm',
          pickerType: 'list',
          inputAttr: "{ 'aria-label': 'Time' }",
          interval: 10,
          startTimeOutOfRangeMessage: this.$_msgContents('CC.MESSAGE.START_TIME_OUT_OF_RANGE', { defaultValue: '시작시간은 종료시간보다 작거나 같아야 합니다.' }),
          endTimeOutOfRangeMessage: this.$_msgContents('CC.MESSAGE.END_TIME_OUT_OF_RANGE', { defaultValue: '종료시간은 시작시간보다 크거나 같아야 합니다.' }),
        },
        defaultData: {
          checked: false,
          specialDt: getPastFromToday(0, 'days'),
          workStart: '09:00',
          workEnd: '18:00',
          checkedDays: false,
          isBreakTime: true,
          breakTimeList: [
            {
              breakStart: '12:00',
              breakEnd: '13:00',
            },
          ],
        },
      },
      specialTime: {
        allChecked: false,
        listData: [],
      },
		};
	},
	computed: {
	},
	methods: {
		/** @description : 기본운영시간설정 운영시간 24시 체크에 따른 운영시간 설정 */
		onChangedCheckedDays(data) {
			let workStart;
			let workEnd;
			if (data.checkedDays) {
				workStart = '00:00';
				workEnd = '00:00';
			} else {
				workStart = '09:00';
				workEnd = '18:00';
			}
			data.workStart = workStart;
			data.workEnd = workEnd;
		},
		/** @description : 휴식시간 여부 변경 이벤트 */
		onChangeIsBreakTime(data) {
			data.breakTimeList = [];
			let breakTime = [];
			if (data.isBreakTime) {
				breakTime = [
					{
						breakStart: '12:00',
						breakEnd: '13:00',
					},
				];
			} else {
				breakTime = [
					{
						breakStart: null,
						breakEnd: null,
					},
				];
			}
			data.breakTimeList = breakTime;
		},
		/** @description : 특별운영시간 설정 휴식시간 추가 이벤트 */
		onAddBreakTime(data) {
			if (data.breakTimeList.length > 2) {
				return false;
			}
			let defaultData = cloneObj(this.config.defaultData.breakTimeList[0]);
			data.breakTimeList.push(defaultData);
		},
		/** @description : 특별운영시간 설정 휴식시간 삭제 이벤트  */
		onRemoveBreakTime(key, index1, index2) {
			if (this[key].listData[index1].breakTimeList.length > 1)
				this[key].listData[index1].breakTimeList.splice(index2, 1);
		},
		/** @description : 공휴일설정/특별운영시간설정 휴일 추가 메서드 */
		onAddData() {
      let data = cloneObj(this.config.defaultData);
      data.idx = this.specialTime.listData.length;
      this.specialTime.listData.push(data);
		},
		/** @description : 공휴일설정/특별운영시간설정 휴일 삭제 메서드 */
		async onDeleteData(key) {
      let checkedData = this[key].listData.filter(d => d.checked);
      let unCheckedData = this[key].listData.filter(d => !d.checked);
      if (checkedData.length === 0) {
        return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
      }

      this[key].listData = unCheckedData;
		},
		/** @description: 특별운영시간 체크박스 전체 선택/해제 */
		onChangeAllCheck(items) {
			if (items.allChecked) {
				items.listData.map(d => (d.checked = true));
			} else {
				items.listData.map(d => (d.checked = false));
			}
		},
		/** @description: 특별운영시간 체크 선택/해제 */
		onChangeChecked(key) {
			let checkedLength = this[key].listData.filter(d => d.checked).length;
			if (checkedLength > 0) {
				if (checkedLength === this[key].listData.length) {
					this[key].allChecked = true;
				} else if (checkedLength < this[key].listData.length) {
					this[key].allChecked = false;
				}
			} else {
				this[key].allChecked = false;
			}
		},
	},
};
</script>
