<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/config/index.vue
  FILE_NAME : index
  AUTHOR : hmlee
  DATE : 2024-10-8
  DESCRIPTION : 운영스케줄관리 설정 페이지
-->
<template>
	<div>
		<div class="page-sub-box">
      <!-- 기본 정보 -->
      <default-info ref="defaultInfo" :checked-days="basicInfo.checkedDays" :basic-info="basicInfo" @changedDaysType="onChangedDaysType"></default-info>

      <div class="page-bin pad_top50"></div>

			<Tabs ref="tabs" :tabType="2">
				<Tab :title="tab" v-for="(tab, index) in tabs.data" :key="index">
					<div class="sub_new_style01 locker_setting_list sub_ui_box1">
            <!-- 기본 운영 시간 설정 -->
            <section class="terms" v-if="parseInt(index) === 0">
              <default-time ref="defaultTime" :checked-days="basicInfo.checkedDays" :basic-config-type="basicTime.configType" :basic-time-list="basicTime.listData"></default-time>
						</section>
            <!-- 공휴일 설정 -->
            <section class="terms" v-if="parseInt(index) === 1">
              <holiday ref="holiday" :checked-days="basicInfo.checkedDays" :holiday-list="holiday.listData" @saving="onSaveHoliday"></holiday>
						</section>
            <!-- 특별 운영 시간 설정 -->
            <section class="terms" v-if="parseInt(index) === 2">
              <special-time ref="specialTime" :special-time-list="specialTime.listData"></special-time>
						</section>
					</div>
				</Tab>
			</Tabs>
		</div>

		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<div class="bottom-btn-wrap">
					<DxButton
            class="default filled txt_S medium"
            :text="$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' })"
            :width="config.button.width"
            :height="config.button.height"
						:use-submit-behavior="config.button.useSubmitBehavior"
						@click="onSaveFormData"
					/>
					<DxButton
            class="white filled txt_S medium"
            :text="$_msgContents('COMPONENTS.CANCEL', { defaultValue: '취소' })"
            :width="config.button.width"
            :height="config.button.height"
            @click="onCancelFormData"
          />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import DefaultInfo from '@/pages/cc/ivr/schedule/config/default-info.vue';
import DefaultTime from '@/pages/cc/ivr/schedule/config/default-time.vue';
import Holiday from '@/pages/cc/ivr/schedule/config/holiday.vue';
import SpecialTime from '@/pages/cc/ivr/schedule/config/special-time.vue';
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';

import { DxButton } from 'devextreme-vue/button';
import { isSuccess, cloneObj, formatDate, checkTimeOverlap } from '@/plugins/common-lib';

export default {
	components: {
    DefaultInfo,
    DefaultTime,
    Holiday,
    SpecialTime,
    Tabs,
    Tab,

		DxButton,
	},
	props: {},
	watch: {},
	data() {
		return {
      tabs: {
				currentTab: 0,
				data: { 0: '기본 운영 시간 설정', 1: '공휴일 설정', 2: '특별 운영 시간 설정' },
			},
      config: {
        button: {
          width: 120,
          height: 40,
          useSubmitBehavior: true,
        },
      },
      basicInfo: { //기본정보
        id: null,
        scheduleNm: null,
        description: null,
        checkedDays: [],
        viewFl: 'Y',
      },
      basicTime: { //기본운영시간
        configType: 0,
        listData: [],
      },
      holiday: { //공휴일
        listData: [],
      },
      specialTime: { //특별운영시간
        listData: [],
      },
    };
	},
	computed: {
	},
	methods: {
    /** @description: 운영요일 변경 이벤트 */
    onChangedDaysType(checkedDays) {
      this.basicInfo.checkedDays = checkedDays;
    },
    /** @description: 공휴일 저장 이벤트 */
    onSaveHoliday(data) {
      this.holiday.listData = data;
    },
		/** @description : 데이터 조회 API 호출 및 데이터 바인딩 */
		async selectFormData() {
			const payload = {
				actionname: 'CC_SCHEDULE_JOINED_LIST',
				data: { id: this.basicInfo.id },
				useErrorPopup: true,
			};
			const res = await this.CALL_CC_API(payload);
			if (isSuccess(res)) {
				const data = { ...res.data.data[0] };
				this.basicInfo.scheduleNm = data.scheduleNm;
				this.basicInfo.description = data.description;
				this.basicInfo.checkedDays = [...new Set(data.scheduleBasics.map(d => d.dayCd).sort((a, b) => a - b))];
        this.basicTime.configType = data.basicConfigType;

        /********** 기본운영시간 **********/
				let basics = [ ...data.scheduleBasics ].sort((a, b) => a.id - b.id); //originData
        if( this.basicTime.configType === 0 ){ //공통설정
          basics = [ basics[0] ];
        }

        const scheduleBasics = [];
				basics.forEach(d => {
          const existing = scheduleBasics.find(d2 => d.dayCd === d2.dayCd);
					if( !existing ) { // 새로운 dayCd일 경우
            const breakStart = d.breakStart;
            const breakEnd = d.breakEnd;
            const breakTime = { breakStart, breakEnd };
            delete d.breakStart, delete d.breakEnd;
            scheduleBasics.push({
              ...d,
              checkedDays: d.workStart === '0000' && d.workEnd === '2400', // 24시간 여부
              isBreakTime: breakStart !== null && breakEnd !== null,    // 휴식 시간 여부
              breakTimeList: [breakTime] // 휴식 시간 리스트
            });

					} else { // 기존 dayCd일 경우, 휴식 시간 추가
            existing.breakTimeList.push({ breakStart: d.breakStart, breakEnd: d.breakEnd });
					}
				});

				scheduleBasics.forEach(d => {
					d.workStart = formatDate(d.workStart, 'HHmm', 'HH:mm');
					d.workEnd = formatDate(d.workEnd, 'HHmm', 'HH:mm');
					if (d.isBreakTime) { //휴식시간 적용시
						d.breakTimeList.forEach(d2 => {
							d2.breakStart = formatDate(d2.breakStart, 'HHmm', 'HH:mm');
							d2.breakEnd = formatDate(d2.breakEnd, 'HHmm', 'HH:mm');
						});

						d.breakTimeList.sort((a, b) => a.breakStart.localeCompare(b.breakStart));
					}
				});

        this.basicTime.listData = scheduleBasics; //originData
        /********** 기본운영시간 **********/

        /********** 공휴일 **********/
        //순서 년월일순으로 설정
        const holidays = data.scheduleHolidays.map(holiday =>{
            if( holiday.holidayType === this.$_enums.common.holidayType.FIXED.value ) { //고정휴일
              holiday.holidayDt = formatDate(holiday.holidayDt, 'MMDD', 'YYYYMMDD');
            }
            return { ...holiday };
        }).sort((a, b) => b.holidayDt - a.holidayDt);
				this.holiday.listData = holidays;
        /********** 공휴일 **********/

        /********** 특별운영시간 **********/
				const specials = data.scheduleSpecials.sort((a, b) => a.specialDt - b.specialDt);
				const scheduleSpecials = [];
				specials.forEach(d => {
          const existing = scheduleSpecials.find(d2 => d.specialDt === d2.specialDt);
          if( !existing ) { // 새로운 dayCd일 경우
            d.checked = false;
            const breakStart = d.breakStart;
            const breakEnd = d.breakEnd;
            const breakTime = { breakStart, breakEnd };
            delete d.breakStart, delete d.breakEnd;
            scheduleSpecials.push({
              ...d,
              checkedDays: d.workStart === '0000' && d.workEnd === '2400', // 24시간 여부
              isBreakTime: breakStart !== null && breakEnd !== null,    // 휴식 시간 여부
              breakTimeList: [breakTime] // 휴식 시간 리스트
            });

          } else { // 기존 dayCd일 경우, 휴식 시간 추가
            existing.breakTimeList.push({ breakStart: d.breakStart, breakEnd: d.breakEnd });
          }

				});

				scheduleSpecials.forEach(d => {
					d.workStart = formatDate(d.workStart, 'HHmm', 'HH:mm');
					d.workEnd = formatDate(d.workEnd, 'HHmm', 'HH:mm');
					if (d.isBreakTime) {
						d.breakTimeList.forEach(d2 => {
							d2.breakStart = formatDate(d2.breakStart, 'HHmm', 'HH:mm');
							d2.breakEnd = formatDate(d2.breakEnd, 'HHmm', 'HH:mm');
						});

						d.breakTimeList.sort((a, b) => a.breakStart.localeCompare(b.breakStart));
					}
				});
				this.specialTime.listData = scheduleSpecials;
        /********** 특별운영시간 **********/

			} else {
				this.$_Toast(vm.$_msgContents('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
			}
		},
		/** @description : 데이터 저장 */
		async onSaveFormData(e) {
      //기본정보
      const defaultInfoRef = this.$refs.defaultInfo;
      this.basicInfo = defaultInfoRef.basicInfo;

      //기본운영시간
      const defaultTimeRef = this.$refs.defaultTime;
      this.basicTime.configType = defaultTimeRef[0].basicTime.configType;
      this.basicTime.listData = defaultTimeRef[0].basicTime.listData;

      //공휴일
      const holidayRef = this.$refs.holiday;
      const holidayGrid = holidayRef[0].$refs.holidayGrid.getGridInstance;
      await holidayGrid.saveEditData();

      //특별운영시간
      const specialTimeRef = this.$refs.specialTime;
      this.specialTime.listData = specialTimeRef[0].specialTime.listData;

			if (!e.validationGroup.validate().isValid) {
				return false;
			}

      //공휴일 유효성 체크
      const isValidHoliday =  holidayGrid.getController('validating')._validationState?.length === 0;
      if( !isValidHoliday ){
        return false;
      }

      //기본 정보
			let id = this.basicInfo.id;
			let scheduleNm = this.basicInfo.scheduleNm;
			let description = this.basicInfo.description;
			let viewFl = this.basicInfo.viewFl;
			let basicConfigType = this.basicTime.configType;

			/********** 기본운영시간 **********/
      const basics = this.basicTime.listData;

			/*** 휴식시간 중복 체크 ***/
			let breakTimeArr = [];
			let isTimeOverlap = false;
			basics.forEach(d1 => {
				breakTimeArr = d1.breakTimeList.map(d2 => {
					//[ ['12:00', '13:00'], ['13:00', '14:00'], ['14:00', '15:00'] ]
					return [d2.breakStart, d2.breakEnd];
				});
				if (checkTimeOverlap(breakTimeArr)) {
					isTimeOverlap = checkTimeOverlap(breakTimeArr);
					return false;
				}
			});

      //휴식시간 중복 체크
			if (isTimeOverlap) {
				return this.$_Msg('휴식시간이 겹칩니다.');
			}
			/*** 휴식시간 중복 체크 ***/

			let scheduleBasics = [];
			if (basicConfigType === 0) { //공통 설정
				const checkedDays = this.basicInfo.checkedDays;
				for (const dayCd of checkedDays) {
					const commonData = cloneObj(basics[0]);
					this.$set(commonData, 'dayCd', dayCd);
          //휴식시간 적용 데이터
					commonData.breakTimeList.forEach(d => {
						scheduleBasics.push({ ...commonData, breakStart: d.breakStart, breakEnd: d.breakEnd });
					});
				}
			} else { //개별 설정
				const eachData = cloneObj(basics);
        eachData.forEach(d1 => {
					if (d1.isBreakTime) { //휴식시간 적용 데이터
						d1.breakTimeList.forEach(d2 => {
							const basicData = { ...d1, breakStart: d2.breakStart, breakEnd: d2.breakEnd };
							scheduleBasics.push(basicData);
						});
					} else { //휴식시간 미적용 데이터
						scheduleBasics.push(d1);
					}
				});
			}

      //date format 변경
			scheduleBasics.forEach(d => {
				d.workStart = formatDate(d.workStart, 'HH:mm', 'HHmm');
				d.workEnd = formatDate(d.workEnd, 'HH:mm', 'HHmm');
				if (d.checkedDays) { //24시간 적용시
					d.workStart = '0000';
					d.workEnd = '2400';
				}
				if (d.isBreakTime) { //휴식시간 적용시
					d.breakStart = formatDate(d.breakStart, 'HH:mm', 'HHmm');
					d.breakEnd = formatDate(d.breakEnd, 'HH:mm', 'HHmm');
				}
        delete d.id, delete d.breakTimeList;
			});
			/********** 기본운영시간 **********/

			/********** 공휴일 **********/
			let holidays = cloneObj(this.holiday.listData);

			holidays = holidays.map(d => {
				d.workFl = d.workFl ? d.workFl : 'N'; //공휴일 근무여부
        if( d.holidayType === this.$_enums.common.holidayType.FIXED.value ){ //고정휴일
          d.holidayDt = formatDate(d.holidayDt, 'YYYYMMDD', 'MMDD');
        }
				return { ...d };
			});

			/*** 공휴일 중복 체크 ***/
			let holdayDtArr = holidays.map(d => (d.holidayDt.length > 4 ? d.holidayDt.substr(4) : d.holidayDt));
			if (new Set(holdayDtArr).size !== holidays.length) {
				this.$_Msg(`동일한 일자의 휴일이 존재합니다.<br/>다시 확인하세요.`);
				return false;
			}
			/*** 공휴일 중복 체크 ***/

			let scheduleHolidays = holidays;
			/********** 공휴일 **********/

			/********** 특별운영시간 **********/
			let specials = cloneObj(this.specialTime.listData);

			/*** 운영일자 중복 체크 ***/
			specials = specials.map(d => {
				d.specialDt = formatDate(d.specialDt).format('YYYYMMDD');
				return { ...d };
			});
			let specialDtArr = specials.map(d => d.specialDt);
			if (new Set(specialDtArr).size !== specials.length) {
				this.$_Msg(`동일한 특별 운영 일자가 존재합니다.<br/>다시 확인하세요.`);
				return false;
			}
			/*** 운영일자 중복 체크 ***/

			/*** 휴식시간 중복 체크 ***/
			breakTimeArr = [];
			isTimeOverlap = false;
			specials.forEach(d1 => {
				breakTimeArr = d1.breakTimeList.map(d2 => {
					//[ ['12:00', '13:00'], ['13:00', '14:00'], ['14:00', '15:00'] ]
					return [d2.breakStart, d2.breakEnd];
				});

				if (checkTimeOverlap(breakTimeArr)) {
					isTimeOverlap = checkTimeOverlap(breakTimeArr);
					return false;
				}
			});

      //휴식시간 중복 체크
			if (isTimeOverlap) {
        return this.$_Msg('휴식시간이 겹칩니다.');
			}
			/*** 휴식시간 중복 체크 ***/

			let scheduleSpecials = [];
			specials.forEach(d1 => {
				d1.breakTimeList.forEach(d2 => {
					scheduleSpecials.push({ ...d1, breakStart: d2.breakStart, breakEnd: d2.breakEnd });
				});
			});

			scheduleSpecials.forEach(d => {
				d.workStart = formatDate(d.workStart, 'HH:mm', 'HHmm');
				d.workEnd = formatDate(d.workEnd, 'HH:mm', 'HHmm');
				if (d.checkedDays) { //24시간 적용시
					d.workStart = '0000';
					d.workEnd = '2400';
				}
				if (d.isBreakTime) { //휴식시간 적용시
					d.breakStart = formatDate(d.breakStart, 'HH:mm', 'HHmm');
					d.breakEnd = formatDate(d.breakEnd, 'HH:mm', 'HHmm');
				}
        delete d.id, delete d.breakTimeList;
			});
			/********* 특별운영시간 **********/

			const data = [
        {
          id: id,
          scheduleNm: scheduleNm,
          description: description,
          basicConfigType: basicConfigType,
          viewFl: viewFl,
          scheduleBasics: scheduleBasics,
          scheduleHolidays: scheduleHolidays,
          scheduleSpecials: scheduleSpecials,
        },
      ];
			//console.log('data >>> ', data);

			if (await this.$_Confirm(`기본 운영시간/공휴일/특별 운영 시간을<br/>함께 저장합니다. 계속하시겠습니까?`)) {
				const payload = {
					actionname: 'CC_SCHEDULE_MERGE',
					data: data,
          useErrorPopup: true,
				};

				const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast('운영스케줄 정보가 변경되었습니다.');
          this.$router.push('/cc/ivr/schedule/list');
				}
			} else {
				return false;
			}
		},
		/** @description: 취소 버튼 클릭시 리스트로 이동 */
		onCancelFormData() {
			this.$router.push('/cc/ivr/schedule/list');
		},
	},
	async created() {
    this.reqParams = this.$store.getters.getDetailParams;

    if (this.reqParams) { //수정
      this.config.isUpdated = true;
      this.basicInfo.id = this.reqParams.id;
      await this.selectFormData();
    }else { //등록
      this.config.isUpdated = false;
      this.basicInfo.checkedDays = [2, 3, 4, 5, 6];
    }
	},
	mounted() {
    this.$refs.tabs.selectTab(0); //탭 초기화
	},
	destroyed() {
    this.$store.commit('setDetailParams', null);
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
  .ecs-sub-tab {
    padding-bottom: 40px;
  }
}
</style>