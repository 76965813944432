<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/config/default-info.vue
  FILE_NAME : default-info
  AUTHOR : hmlee
  DATE : 2024-10-8
  DESCRIPTION : 운영스케줄관리 설정 - 기본정보 컴포넌트
-->
<template>
  <table class="table_form line-bin">
    <caption>
      <strong>{{ $_msgContents('CC.WORD.BASIC_INFO', { defaultValue: '기본 정보' }) }}</strong>
    </caption>

    <colgroup>
      <col style="width: 130px" />
      <col style="width: auto" />
    </colgroup>

    <thead class="sub_title_txt">
      <tr>
        <td colspan="2"><h2>{{ $_msgContents('CC.WORD.BASIC_INFO', { defaultValue: '기본 정보' }) }}</h2></td>
      </tr>
    </thead>

    <tbody>
      <tr>
        <th scope="row">
          <label for="label5">{{ $_msgContents('CC.WORD.OP_SCHEDULE_NAME', { defaultValue: '운영 스케줄명' }) }} <span class="icon_require"></span></label>
        </th>
        <td>
          <DxTextBox
            v-model="basicInfo.scheduleNm"
            :max-length="config.limitNumberTexts.maxLengths.scheduleNm"
            :styling-mode="config.stylingMode"
            class="mar_ri10"
            :width="390"
            @key-up="$_checkLimitTextLength($event, basicInfo, config.limitNumberTexts, 'scheduleNm')"
          >
            <DxValidator>
              <DxRequiredRule :message="$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: $_msgContents('CC.WORD.OP_SCHEDULE_NAME', {defaultValue: '운영 스케줄명'}) })" />
            </DxValidator>
          </DxTextBox>
          {{
            config.limitNumberTexts.textLengths.scheduleNm
              ? config.limitNumberTexts.textLengths.scheduleNm
              : basicInfo.scheduleNm
              ? basicInfo.scheduleNm.length
              : 0
          }}/{{ config.limitNumberTexts.maxLengths.scheduleNm }}자
        </td>
      </tr>
      <tr>
        <th scope="row" class="alT"><label for="label7">{{ $_msgContents('CC.WORD.DESCRIPTION', { defaultValue: '설명' }) }}</label></th>
        <td>
          <DxTextArea
            v-model="basicInfo.description"
            :max-length="config.limitNumberTexts.maxLengths.description"
            :styling-mode="config.stylingMode"
            class="mar_ri10 alB"
            :width="390"
            :height="100"
            @key-up="$_checkLimitTextLength($event, basicInfo, config.limitNumberTexts, 'description')"
          >
          </DxTextArea>
          {{
            config.limitNumberTexts.textLengths.description
              ? config.limitNumberTexts.textLengths.description
              : basicInfo.description
              ? basicInfo.description.length
              : 0
          }}/{{ config.limitNumberTexts.maxLengths.description }}자
        </td>
      </tr>
      <tr>
        <th scope="row">{{ $_msgContents('CC.WORD.OP_DAY_OF_WEEK', { defaultValue: '운영 요일' }) }}</th>
        <td>
          <div>
            <span class="check-type col" v-for="(item, index) in config.days" :key="index">
              <DxCheckBox
                :text="item.label"
                v-model="item.isChecked"
                :data="item.value"
                :value="item.value"
                @value-changed="onChangedDaysType"
              />
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <th scope="row">{{ $_msgContents('COMPONENTS.USE_STATUS', { defaultValue: '사용여부' }) }}</th>
        <td>
          <DxSwitch @value-changed="onChangedViewFl($event)" :value="getViewFl" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { DxRangeRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';

import {
	DxColumn,
	DxDataGrid,
	DxEditing,
	DxLookup,
	DxPager,
	DxPaging,
	DxScrolling,
	DxSelection,
} from 'devextreme-vue/data-grid';

import { DxTextBox } from 'devextreme-vue/text-box';
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxSwitch } from 'devextreme-vue/switch';

export default {
	components: {
		DxValidator,
		DxRequiredRule,
		DxRangeRule,

		DxDataGrid,
		DxColumn,
		DxLookup,
		DxScrolling,
		DxPaging,
		DxPager,
		DxEditing,
		DxSelection,

		DxTextBox,
		DxTextArea,
		DxCheckBox,
		DxSwitch,
	},
	props: {
    /** @description: 선택된 요일 */
    checkedDays: {
      type: Array,
    },
    /** @description: 기본 정보 */
    basicInfo: {
      type: Object,
    },
  },
	watch: {
    /** @description: 선택된 요일 데이터 */
    checkedDays: {
      handler: 'getDayTypeList',
    },
    /** @description: 기본 정보 데이터 */
    basicInfo(value) {
      this.basicInfo = value;
    },
  },
	data() {
		return {
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        days: [], //운영요일
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            scheduleNm: 50,
            description: 200,
          },
        },
      },
		};
	},
	computed: {
    /** @description: 사용여부 */
    getViewFl() {
      return this.basicInfo.viewFl === 'Y';
    },
	},
	methods: {
    /** @description : 운영요일 리스트 정보 가져오기 */
    getDayTypeList() {
      this.config.days = this.$_enums.common.integerDayOfWeek.values.map(day => {
        if( this.checkedDays.some(checkedDay => checkedDay === day.value) ){
          day.isChecked = true;
        } else {
          day.isChecked = false;
        }
        return { ...day };
      }).sort((a, b) => a.sort - b.sort);
    },
		/** @description : 운영요일 Checkbox 변경시 이벤트 */
		onChangedDaysType() {
      let checkedDayList = this.config.days.filter(d => d.isChecked);
      const checkedDays = checkedDayList.map(day => day.value);
      this.$emit('changedDaysType', checkedDays);
		},
    /** @description : 사용여부 이벤트 */
    onChangedViewFl(e) {
      if (e.value) this.basicInfo.viewFl = 'Y';
      else this.basicInfo.viewFl = 'N';
    },
	},
  created() {
    this.getDayTypeList();
  },
};
</script>
